@tailwind base;
@tailwind components;
@tailwind utilities;

  @import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

  .profile {
    background-image: url("/public/red-blur.png");
    background-repeat: no-repeat;
    background-size: cover;
  }

  .wallet {
    background-image: url("/public/pink-blur.png");
    background-repeat: no-repeat;
    background-size: cover;
  }

  .autopool {
    background-image: url("/public/tiffany-blur.webp");
    background-repeat: no-repeat;
    background-size: cover;
  }

html {
    ::-webkit-scrollbar-corner {
        background-color: transparent;
    }

    ::-webkit-scrollbar-thumb {
        background-color: rgba(255,255,255,.25);
        border-radius: 20px;
    }

    ::-webkit-scrollbar {
        width: 7.5px;
        height: 7.5px;
    }
}

body {
    background-color: #18191a;
    color: white;
}

:root {
  --inset: 1vmin;
  --color0: #0ac;
  --color1: #222;
  --duration: 5s;
  --bgcolor0: #0aca;
  --bgcolor1: #222a;
  --d: 2500ms;
	--angle: 90deg;
	--gradX: 100%;
	--gradY: 50%;
	--c1: rgba(168, 239, 255, 1);
	--c2: rgba(168, 239, 255, 0.1);
}


.sub-bg {
   background-color: #ffffff1a;
}


/* Avatar */
.com {
    inline-size: 80px;
    display: grid;
    place-items: center;
    transform: rotate(45deg);
    overflow: hidden;
    box-shadow: inset 0 0 10px black, 0 0 10px black;
    filter: drop-shadow(0 0 10px var(--color0)) saturate(2);
  }
  
  .slots {
    transform: rotate(45deg);
    /* box-shadow: inset 0 0 6px black, 0 0 6px black;
    filter: drop-shadow(0 0 10px var(--color0)) saturate(2); */
  }
  
  .com .th {
    inline-size: 100%;
    block-size: 100%;
    overflow: hidden;
    clip-path: inset(var(--inset));
  }
  
  .com .th img {
    display: block;
    inline-size: 100%;
    block-size: 100%;
    object-fit: cover;
    transform: rotate(-45deg) scale(1.6);
  }
  
  .com::before {
    content: '';
    display: block;
    inline-size: 100%;
    block-size: 100%;
    position: absolute;
    background: repeating-conic-gradient(transparent, var(--color0) 25deg, var(--color1) 22.5deg, transparent 90deg);
    animation: var(--duration) f linear infinite;
    transform: scale(1.6);
  }
  
  @keyframes f {
    0% {
      transform: rotate(0);
    }
    100% {
      transform: rotate(1turn);
    }
  }
  
  @supports not (rotate: 0deg) {
    .com {
      transform: rotate(45deg);
    }
  
    .com .th img {
      transform: rotate(-45deg) scale(1.6);
    }
  
    .com::before {
      transform: scale(1.6);
      animation-name: g;
    }
  }
  
  @keyframes g {
    0% {
      transform: rotate(0) scale(1.6);
    }
    100% {
      transform: rotate(1turn) scale(1.6);
    }
  }
  
  /* Slots Animation */

@property --angle {
  syntax: '<angle>';
  initial-value: 90deg;
  inherits: true;
}

@property --gradX {
  syntax: '<percentage>';
  initial-value: 50%;
  inherits: true;
}

@property --gradY {
  syntax: '<percentage>';
  initial-value: 0%;
  inherits: true;
}

.box {
	border: 0.1rem solid;
	border-image: conic-gradient(from var(--angle), var(--c2), var(--c1) 0.1turn, var(--c1) 0.15turn, var(--c2) 0.25turn) 30;
	animation: borderRotate var(--d) linear infinite forwards;
}

.box:nth-child(2) {
	border-image: radial-gradient(ellipse at var(--gradX) var(--gradY), var(--c1), var(--c1) 10%, var(--c2) 40%) 30;
	animation: borderRadial var(--d) linear infinite forwards;
}

@keyframes borderRotate {
	100% {
		--angle: 420deg;
	}
}

@keyframes borderRadial {
	20% {
		--gradX: 100%;
		--gradY: 50%;
	}
	40% {
		--gradX: 100%;
		--gradY: 100%;
	}
	60% {
		--gradX: 50%;
		--gradY: 100%;
	}
	80% {
		--gradX: 0%;
		--gradY: 50%;
	}
	100% {
		--gradX: 50%;
		--gradY: 0%;
	}
}
 
  
  
  /* Rotate */
  .rotate {
    animation: rotation 40s infinite linear;
  }
  
  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }


  /* Text Gradient */

  .txtgradient {
    background: linear-gradient(90deg,#f0b90b,#d358a9);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .blackbg {
    background: linear-gradient(180deg,#3a3b3c,#242526 99.58%);
  }

  .bggradient {
    background: radial-gradient(117.67% 99.18% at 50% .82%,#ffb23e 0,#ff518f 50.39%,#622aff 100%);
  }

  .bggradient2:before {
    z-index: -1;
    position: absolute;
    content: "";
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(55.7deg,#ffb23e,#ff518f 50.39%,#622aff);
    -webkit-filter: blur(100px);
    filter: blur(100px);
    border-radius: 40px;
  }

  .button-29 {
    align-items: center;
    appearance: none;
    background-image: radial-gradient(100% 100% at 100% 0, #5adaff 0, #5468ff 100%);
    border: 0;
    border-radius: 6px;
    box-shadow: rgba(45, 35, 66, .4) 0 2px 4px,rgba(45, 35, 66, .3) 0 7px 13px -3px,rgba(58, 65, 111, .5) 0 -3px 0 inset;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    display: inline-flex;
    font-family: "JetBrains Mono",monospace;
    height: 48px;
    justify-content: center;
    line-height: 1;
    list-style: none;
    overflow: hidden;
    padding-left: 16px;
    padding-right: 16px;
    position: relative;
    text-align: left;
    text-decoration: none;
    transition: box-shadow .15s,transform .15s;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    white-space: nowrap;
    will-change: box-shadow,transform;
    font-size: 18px;
  }
  
  .button-29:focus {
    box-shadow: #3c4fe0 0 0 0 1.5px inset, rgba(45, 35, 66, .4) 0 2px 4px, rgba(45, 35, 66, .3) 0 7px 13px -3px, #3c4fe0 0 -3px 0 inset;
  }
  
  .button-29:hover {
    box-shadow: rgba(45, 35, 66, .4) 0 4px 8px, rgba(45, 35, 66, .3) 0 7px 13px -3px, #3c4fe0 0 -3px 0 inset;
    transform: translateY(-2px);
  }
  
  .button-29:active {
    box-shadow: #3c4fe0 0 3px 7px inset;
    transform: translateY(2px);
  }


  .blue_gradient {
    background: #0f0c29;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #24243e, #302b63, #0f0c29);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #24243e, #302b63, #0f0c29); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  }